import isEmpty from "lodash/isEmpty";

import {
	ANNUAL_PLANS,
	CHECKOUT_VARIANTS,
	MONTHLY_PLANS,
	PLANS_WITH_FLAC_STREAMING,
	PURCHASE_CATEGORIES,
} from "@lib/constants/subscriptions";

/**
 * Checks if the plan code belongs to an annual plan
 * @param {string} planCode - unique plan code
 * @returns {boolean} - returns true if plan code belongs to an annual plan
 */
export const isAnnualPlanCode = (planCode?: string): boolean =>
	planCode ? ANNUAL_PLANS.includes(planCode) && !MONTHLY_PLANS.includes(planCode) : false;

/**
 * Checks if the current subscription is annual
 * @param {Plan} plan - plans that is being checked
 * @returns {boolean} - returns true if plan is annual
 */
export const isAnnualPlan = (plan: Plan): boolean => isAnnualPlanCode(plan.plan_code);

/**
 * Checks if a plan matches current user subscription
 * @param {Plan} plan - plan that is being checked
 * @param {MySubscription} mySubscription - users current subscription
 * @returns {boolean} - returns true if plan code matches current subscription plan code
 */
export const isActivePlan = (plan: Plan, mySubscription?: MySubscription): boolean =>
	mySubscription?.subscription?.bundle?.plan_code === plan.plan_code;

/**
 * Checks if user used up their free trial
 * @param {MySubscription} mySubscription - users current subscription
 * @returns {boolean} - returns true if user used up their free trial
 */
export const hasUsedFreeTrial = (mySubscription?: MySubscription): boolean =>
	Boolean(
		!isEmpty(mySubscription) &&
		!isEmpty(mySubscription?.subscription) &&
		!isEmpty(mySubscription?.status));

/**
 * Checks if user is eligible for free trial for a specific plan
 * @param {boolean} hadTrial - true if user has previously had free trial
 * @param {Plan} plan - plan that is being checked
 * @returns {boolean} - returns false if user previously had free trial, or plan
 * 						is does not qualify for free trial period
 */
export const isEligibleForTrial = (hadTrial: boolean, plan: Plan): boolean =>
	!hadTrial && plan.plan_trial_interval_length > 0;

/**
 * Check if the user's subscription has FLAC streaming available using the PLANS_WITH_FLAC_STREAMING constant.
 * @param mySubscription The user's subscription data.
*/
export function hasFlacStreamingAvailable(mySubscription?: MySubscription): boolean {
	return PLANS_WITH_FLAC_STREAMING.includes(mySubscription?.subscription?.bundle?.plan_code ?? "");
}

/**
 * Removes decimals if value is a round number, otherwise enforces 2 decimals
 * Example
 *  - 10.00 > 10
 *  - 10.5 > 10.50
 *  - 10.03 > 10.03
 *  - 10.12345 > 10.12
 *  - 10 	> 10
 * @param {string|number} value - value to be formated
 * @returns {string} - formated value to be displayed
 */
export const formatDisplayPrice = (value: string | number): string => {
	const numericValue = typeof value === "string" ? parseFloat(value) : value;

	if (isNaN(numericValue)) {
		return String(value);
	}

	if (Number.isInteger(numericValue)) {
		return numericValue.toString();
	}

	// Truncate the number to two decimal places, ensuring no rounding errors
	const truncatedValue = Math.floor(numericValue * 100) / 100;

	// If the number has one decimal place, ensure two decimal places
	if (truncatedValue * 10 === Math.floor(truncatedValue * 10)) {
		return truncatedValue.toFixed(2);
	}

	// Return up to 2 decimal places, removing unnecessary trailing zeros
	return truncatedValue.toFixed(2).replace(/\.?0+$/, "");
};

/**
 * Removes decimals if price is a round number, otherwise enforces 2 decimals
 * Example
 *  - USD$10.00 > USD$10
 *  - USD$10.5 > USD$10.50
 *  - USD$10.03 > USD$10.03
 *  - USD$10.12345 > USD$10.12
 *  - USD$10 	> USD$10
 * @param {Plan} plan - plan whose plan_price is being parsed
 * @param {str} currency - key used to parse retrieve the specific value in plan_price
 * @returns {string} - formated value to be displayed
 */
export const formatCardDisplayPrice = (plan: Plan, currency: string): string => {
	const priceWithCurrency = plan.plan_price[currency];
	const numericValue = parseFloat(priceWithCurrency.replace(/[^\d.-]/g, ""));
	const formattedPrice = formatDisplayPrice(numericValue);
	return priceWithCurrency.replace(/[0-9.-]+/, formattedPrice);
};

/**
 * Applies coupon discount
 * @param {number} price - plan price
 * @param {number} discount - coupon discount %, valid values in range [0, 100]
 * @returns Plan price after coupon has been applied. In case of an invalid discount value,
 * we just return original price.
 */
export const formatTotalAfterCoupon = (price: number, discount: number): string => {
	if (discount <= 0 || discount > 100 || isNaN(discount)) {
		return price.toFixed(2);
	}
	const couponAmount = parseFloat((price * (discount / 100)).toFixed(2));
	const totalAfterCoupon = price - couponAmount;
	return totalAfterCoupon.toFixed(2);
};

/**
 * Determines product variant for data layer
 */
export const getCheckoutVariant = ({
	isActivePlan,
	isUpdate,
	canUseTrial,
}: {
	isActivePlan: boolean;
	isUpdate: boolean;
	canUseTrial: boolean;
}): string => {
	return isActivePlan ?
		CHECKOUT_VARIANTS.SUBSCRIPTION_NO_CHANGE :
		isUpdate ?
			CHECKOUT_VARIANTS.SUBSCRIPTION_UPDATE :
			canUseTrial ?
				CHECKOUT_VARIANTS.TRIAL :
				CHECKOUT_VARIANTS.NEW_SUBSCRIPTION;
};

/**
 * Determines purchase variant for data layer
 */
export const getPurchaseVariant = ({
	canUseTrial,
}: {
	canUseTrial: boolean;
}): string =>
	canUseTrial ?
		PURCHASE_CATEGORIES.FREE_TRIAL :
		PURCHASE_CATEGORIES.SUBSCRIPTION;
